.baseRouteHeaderContentWrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: clip;
}
.mainContainer {
  position: relative !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 32px;
  overflow: clip;
}
