.motifContainer {
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 20px;
}

.widthComplete {
  width: 100%;
  font-weight: 400 !important;
}

.tabPadding {
  padding: 8px 0px 12px 0px !important;
}

.tabPadding:not(:first-child):not(:last-child) {
  padding: 8px 10px 12px 10px !important;
}

.fullWidth {
  width: 100%;
}

.selectedTabColor {
  color: #ffffff !important;
}
