.title {
  color: #f3f3f5;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  word-wrap: break-word;
  margin: 0px;
}
.infoIcon {
  margin: {
    top: 5px;
    left: 10px;
  }
}
.rightSide {
  justify-content: flex-end;
}
.widgetSelector {
  width: 90%;
  button {
    padding: 0px;
    padding-left: 14px;
  }
}
