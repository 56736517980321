.container {
  margin-bottom: 12px;
}

.foreignObject {
  border-radius: 2px;
  &:not(:focus):hover {
    transform: scale(1.015);
    transform-origin: center center;
    transition-delay: 0.5s;
  }
}

.foreignObjectSelected {
  border-radius: 2px;
}

.foreignObjectNotSelected {
  border-radius: 2px;
  opacity: 0.5;
  &:not(:focus):hover {
    transform: scale(1.015);
    transform-origin: center center;
    opacity: 1;
    transition-delay: 0.5s;
  }
}

.textContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.textColorInitialLevel {
  color: var(--Neutrals-Dark-theme-1000, #fff);
}

.textColorGreaterLevel {
  color: var(--Neutrals-Dark-theme-00, #1a1a24);
}

.textContainerFlexStart {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
}

.container {
  border-radius: 6px;
  background: var(--Neutrals-Dark-theme-100, #33333e);
}

.countContainer {
  position: fixed;
  left: 5px;
  bottom: 5px;
  padding: 0px 2px 0px 2px;
  border: 2px solid var(--Neutrals-Light-theme-600, #7f7f91);
  font-size: 12px;
  color: var(--Neutrals-Light-theme-700, #656579);
  background: var(--Neutrals-Light-theme-100, #e6e6e9);
}
