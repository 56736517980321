.text {
  color: var(--neutral-light-400, #afaeba);
}

.legend {
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 15px;
}

.percentage {
  min-width: 36px;
  text-align: right;
}
