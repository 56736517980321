.borderBottom {
  border-bottom: 1px solid #4e4e5d;
}

.zoomBox {
  border: 1px solid var(--Neutrals-Dark-theme-700, #a4a3b1);
  border-radius: 20px;
  position: absolute;
  bottom: 100px;
  right: 100px;
  width: 212px;
  height: 34px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.zoomIn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-style: none none none none;
  background: var(--Neutrals-Dark-theme-200, #3f3f4c);
  height: 100%;
  &:hover {
    opacity: 0.8;
  }
}

.zoomPercentage {
  padding: 10px;
  font-size: 17px;
  border: 1px solid var(--Neutrals-Dark-theme-200, #a4a3b1);
  border-style: none solid none solid;
  text-align: center;
  width: 66.164px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--neutrals-dark-theme-25, #23232f);
}

.plainLoader {
  background-color: transparent;
}
