.checkBoxContainer {
  margin-top: 3px;
  padding: {
    top: 16px;
    bottom: 16px;
  }
  > label {
    margin: {
      top: 0px !important;
      right: 12px;
    }
  }
  > label:hover {
    text-decoration: none !important;
  }
}
.frameworkOverlap {
  margin-top: 14px;
}
.vennDiagramContainer {
  min-height: 300px;
}

.OASelectContainer {
  margin-top: 26px;
}

.dropdownItem {
  border: none;
}

.overlapAnalysisCheckboxContainer {
  border: 1px solid #33333e;
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  background-color: #23232f;
  position: absolute;
  top: 6px;
  width: 100%;
  left: 0;
  border-radius: 4px;

  .selectALlCheckbox {
    border-bottom: 1px solid #33333e !important;
  }

  .regulationNameCheckbox {
    margin-left: 6px;
  }
}

.overlapAnalysisChips {
  padding: 4px 2px;
  margin: 0;
  height: 44px;
  border: 1px solid #4e4e5d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin-top: 24px;

  .selectedRegulations {
    display: inline-flex;
    margin-left: 4px;
    .regulationNameCheckbox {
      margin-left: 6px;
    }
  }

  .showDropdownIcon {
    margin-right: 8px;
  }
}

.marginAuto {
  margin: 0 auto;
}
