.headingValues {
  margin: {
    top: 5px;
    bottom: 0;
  }
}

.smallWidgets {
  min-height: 190px;
  height: 100%;
}
