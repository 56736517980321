.barContainer {
  display: flex;
  height: 18px;
  border-radius: 2.86px;
  border: 1px solid white;
  position: relative;
  div {
    display: flex;
    padding: 3px;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: EYInterstate;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
  }
  div:not(:last-child) {
    border-right: 0.715px solid #33333e;
  }
}
.frameworkContainer {
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    width: 100%;
  }
}
.overlapName {
  p {
    font-size: 11.44px;
    font-style: normal;
    line-height: normal;
    margin: {
      top: 10px;
      bottom: 4px;
    }
  }
}
.bars {
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
.regulationName {
  position: absolute;
  color: #fff !important;

  text-align: center;
  font-family: EYInterstate !important;
  font-size: 7px !important;
  font-style: normal !important;
  line-height: normal !important;
  right: 0;
  top: 1px;
}
