.diagramContainer {
  font-size: 14px;
  path {
    fill: none;
    fill-opacity: 0.4;
  }
  text {
    text-anchor: middle;
    dominant-baseline: central;
    fill: white;
  }

  text:nth-of-type(2) {
    fill: white;
    width: 11px;
    height: 11px;
    outline: 1px solid rgba(255, 255, 255, 20%);
    outline-offset: 1px;
  }
  text:nth-of-type(3) {
    fill: white;
  }
}
