.headingNode {
  font-size: 12px;
  text-align: left;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-y: auto;
  font-weight: bold;
}

.contentBorder {
  border-top: 1px solid var(--Neutrals-Dark-theme-100, #33333e);
  padding: 8px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.searchBox {
  & button[title='Search'] {
    background: none !important;
    &:hover {
      background: none !important;
    }
  }
}

.filterIcon {
  pointer-events: all;
  margin-left: 4px;
  height: 20px;
  line-height: 0;
}
.infoIcons {
  margin-top: 5px;
  margin-left: 5px;
}
