.container {
  padding: 150px 24px 32px 24px;
}

.fixedContainer {
  z-index: 10;
  position: fixed;
  background-color: inherit;
  width: 97vw !important;
}

.fixedContainerWithSideBar {
  z-index: 10;
  position: fixed;
  background-color: inherit;
  width: 83vw !important;
}

.displayFlexRow {
  display: flex;
  flex-direction: row;
}

.displayFlexColumn {
  display: flex;
  flex-direction: column;
}

.heading {
  justify-content: space-between;
  width: calc(98vw - 283px);
}

.headingWithoutSideNav {
  justify-content: space-between;
  width: calc(94vw);
}

.topTag {
  padding: 4px 4px 4px 10px;
  border: 2px solid var(--Neutrals-Light-theme-600, #7f7f91);
  margin-left: 8px;
}

.dataGridHeaderCardWithSideBar {
  width: 79vw;
  padding: 24px 24px;
  background: var(--Neutrals-Dark-theme-100, #33333e);
  :global {
    .MuiPaper-root > .MuiBox-root {
      background-color: var(--card--bg-color) !important;
    }
  }
}

.dataGridHeaderCard {
  width: 94vw;
  padding: 24px 24px;
  background: var(--Neutrals-Dark-theme-100, #33333e);
  :global {
    .MuiPaper-root > .MuiBox-root {
      background-color: var(--card--bg-color) !important;
    }
  }
}

.dataGridHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.dataGridHeaderSubtitle {
  color: #a4a3b1;
}
.dataGridDownload {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: -12px;
  border-radius: 6px;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  :global {
    .motif-icon {
      height: 26px;
      width: 26px;
    }
  }
}

.selectedButton {
  background: var(--Neutrals-Dark-theme-800, #bfbfc8);
  color: #2e2e38;
}
