.customMotifCard {
  border-radius: 6px;
  display: flex;
  overflow: unset;
  border: 1px solid var(--neutrals-dark-theme-100, #33333e);
  background: var(--neutrals-dark-theme-25, #23232f);
  padding: {
    top: 16px;
    left: 24px;
    right: 24px;
    bottom: 24px;
  }
}

.widgetSpacing {
  margin-top: 24px;
}

.pageContainerColor {
  background-color: #1a1a24;
  @media (max-width: 1366px) {
    margin-left: 3.714rem;
  }
}
.no-padding {
  padding: 0px;
}
.no-margin {
  margin: 0px;
}

.pageHeading {
  h2 {
    color: var(--Grey-600, #fff);
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
  }
  .motif-label {
    color: var(--Neutrals-Dark-theme-700, #a4a3b1);
    font-family: EYInterstate;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem; /* 150% */
  }
}

.sub-headings {
  color: #fff;
  font-family: EYInterstate;
  font-size: 15.87px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headerToolTip {
  .motif-tooltip {
    max-width: 400px !important;
  }
}

/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .widgetBox {
    margin-top: 10px;
  }
}

.sankeyNodeFilterContainer {
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  background-color: #23232f;
  .motif-checkbox-label-wrap {
    display: flex;
    padding: 10px 10px;
    gap: 8px;
    min-width: 150px;
  }
  .motif-checkbox-label {
    color: var(--Neutrals-Dark-theme-900, #dedee2);
    text-overflow: ellipsis;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    text-decoration: none !important;
  }
  .motif-checkbox-label-wrap:hover {
    text-decoration: none !important;
    background-color: #2e2e38 !important;
  }
}

.loading-text {
  animation: blinker 1s linear infinite;
  padding-left: 10px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.profileHeaderContainer {
  .motif-dropdown-menu {
    right: 12px !important;
    top: 48px !important;
    width: auto;
  }

  .motif-dropdown-item {
    height: 72px;
    padding: 10px 14px;

    .motif-icon {
      display: flex;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .motif-dropdown-menu:before {
    display: none;
  }

  .motif-body1-default-light {
    color: #a4a3b1;
  }
}

.overlapAnalysisDropdownContainer {
  .motif-dropdown-trigger {
    width: 100% !important;
  }

  .motif-dropdown-menu {
    width: 100%;
    left: 0 !important;
    border: none;

    .motif-dropdown-item:hover {
      background: transparent !important;
      cursor: auto;
      --dropdown-menu-item--color: #dedee2 !important;
    }
  }

  .motif-dropdown-menu:before {
    display: none;
  }

  .motif-dropdown-item {
    border: none !important;

    .motif-checkbox-label-wrap {
      display: flex;
      padding: 10px 10px;
      gap: 8px;
      min-width: 150px;
      height: 44px;
      width: 100%;
    }

    .motif-checkbox-label-wrap:hover {
      background: #2e2e38 !important;
      .motif-checkbox-label {
        text-decoration: none !important;
      }
    }
  }

  .overlapAnalysisChips {
    .motif-chip {
      width: fit-content;
      height: fit-content;

      .motif-chip-button.motif-chip-removable,
      .motif-chip-button.motif-chip-interactive {
        border: 1px solid #4e4e5d !important;
        background: #23232f !important;
        height: 100%;
        width: 100%;
      }
    }
  }
}
