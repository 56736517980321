.innerContainer {
  margin-top: 44px;
  align-content: center;
}

.noHeight {
  line-height: 0%;
}

.infoIcon {
  margin: {
    top: 10px;
    left: 10px;
  }
}

.borderStyle {
  border-left: 1px solid rgba(243, 243, 245, 0.3);
  border-right: 1px solid rgba(243, 243, 245, 0.3);
}

.flexCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.metricsHeading {
  margin-top: 10px;
  margin-bottom: 10px;
}
