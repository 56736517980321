.topicCard {
  width: 100%;
}

.topicFooter {
  display: flex;
  padding-top: 16px;
  margin-top: 12px;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--Neutrals-Dark-theme-100, #33333e);
}

.topicViewReport {
  cursor: pointer;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  transition: all 0.3s ease;
}
.topicViewReport:hover {
  gap: 12px;
  margin-right: -4px;
}

.topicFooterIcon {
  height: 20px;
  width: 20px;
}
