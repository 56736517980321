.selectbox {
  button {
    padding: 0px;
    padding-left: 14px;
  }
}

.selectboxLabel {
  white-space: nowrap;
}

.dataGridHeaderCard {
  width: 93vw !important;
  overflow-x: auto;
  :global {
    .MuiPaper-root > .MuiBox-root {
      background-color: var(--card--bg-color) !important;
    }
  }
}

.dataGridHeaderCardWithSideNav {
  width: calc(97vw - 283px) !important;
  overflow-x: auto;
  :global {
    .MuiPaper-root > .MuiBox-root {
      background-color: var(--card--bg-color) !important;
    }
  }
}

.popupDropdownWithSideNav {
  width: calc(98vw - 283px) !important;
}

.popupDropdownWithoutSideNav {
  width: 95vw !important;
}

.dataGridHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
}

.dataGridHeaderSubtitle {
  color: #a4a3b1;
}
.dataGridDownload {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: -12px;
  border-radius: 6px;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  :global {
    .motif-icon {
      height: 26px;
      width: 26px;
    }
  }
}

.topicCardWrapper {
  margin-bottom: 18px;
}
