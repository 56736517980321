.topComponent {
  align-items: center;
}

.topComponentText {
  color: var(--Neutrals-Light-theme-50, #f3f3f5);
  margin-left: 10px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 2.25rem;
}

.bottomComponent {
  padding-left: 5px;
  color: var(--Neutrals-Light-theme-50, #f3f3f5);
  margin-top: 10px;
  font-family: EYInterstate;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.625rem;
  letter-spacing: -0.0425rem;
}
