.topicTitle {
  display: flex;
  gap: 12px;
  align-items: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}

.topicTitleIcon {
  padding: 12px;
  min-width: 48px;
  aspect-ratio: 1/1;
  display: inline-flex;
  border-radius: 50%;
}
