.nodeContent {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 100%;
  border: 2px solid #5f5f72;
  border-radius: 7px;
}
.nodeContent:hover {
  background-color: #2e2e38;
  cursor: pointer;
}
.expandedNode {
  overflow: auto;
}
.highlightNode {
  border: 2px solid #ecbdda;
}
.nameBox {
  width: 100%;
  height: 100%;
  overflow-wrap: break-word !important;
  word-wrap: break-word;
  text-overflow: ellipsis;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.4;
  padding: {
    left: 10px;
    right: 10px;
  }
}
.nameBoxWithBottomChips {
  height: calc(100% - 21px);
  text-overflow: ellipsis !important;
}
.hideOverflow {
  overflow: hidden;
}
.smallWordClass {
  text-align: center;
  display: flex;
  align-items: center;
}

.bottomBtnContainer {
  position: absolute;
  bottom: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
}

.expansionIcons {
  bottom: 5px;
  right: 3px;
  width: 15px;
  height: 15px;
  margin-left: 3px;
  cursor: ns-resize;
}

.nodeChip {
  > div {
    padding: 2px 5px !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 10px !important;
  }
}

.dotChip {
  > div {
    border-radius: 4px !important;
    color: #dedee2 !important;
  }
}

.normalChip {
  > div {
    color: #23232f !important;
  }
}
