.userAvatar {
  margin-right: 16px;
  margin-left: 16px;
  height: 46px;
  width: 46px;

  img {
    padding: 2px;
  }
}

.visibleProfile {
  border: 2px solid #85b9fd;
}

.logOut {
  border-top: 1px solid #33333e;
  height: 52px !important;
}
