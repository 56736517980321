.modalCloseButton {
  border-radius: 4px;
  border: 1px solid var(--Neutrals-Dark-theme-300, #4e4e5d);
  width: 120px;
}

.modalBodyHeading {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 4px 0 4px 0;
  margin: 0px;
  color: var(--Neutrals-Dark-theme-700, #a4a3b1);
}

.modal {
  padding: 24px !important;
  min-width: 970px !important;
  height: 720px;
}

.contentBorder {
  border-top: 1px solid var(--Neutrals-Dark-theme-100, #33333e);
  padding: {
    top: 8px !important;
    bottom: 10px !important;
  }
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.modalWindowIcon {
  height: 50px;
  width: 50px;
}
.modalHeaderBox {
  border: 0px;
  margin: 0px !important;
  & > h3 {
    padding-top: 8px !important;
    padding-left: 0px !important;
  }
  & > [data-testid*='close-modal'] {
    svg : {
      background: #a4a3b1 !important;
      transform: scale(0.8);
    }
    &:hover,
    &:visited,
    &:focus,
    &:active {
      background: none !important;
    }
  }
}
.headingText {
  font-family: EYInterstate;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  letter-spacing: 0em !important;
  text-align: left;
  margin-top: 8px !important;
  color: #dedee2 !important;
}
.modalHeaderBoxInner {
  display: flex;
  flex-direction: column;
  padding: 0px !important;
}
.removePadding {
  padding: 0px;
}
.textValue {
  margin: 0px;
  padding-top: 4px;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #e6e6e9;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.footer {
  align-items: end;
  padding: {
    top: 0px !important;
    bottom: 0px !important;
  }
}
.fixPadding {
  padding: 0px 15px 0px 0px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
